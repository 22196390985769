import React, { useEffect } from "react";

import { navigate } from "gatsby";

import { Layout } from "../components";

export const NotFoundPage = () => {
  useEffect(() => {
    navigate("/").catch(() => undefined);
  });

  return <Layout />;
};

export default NotFoundPage;
